import Turbolinks from "turbolinks"

class ProductVersionSelector {
  static start() {
    let app = new ProductVersionSelector()
    return app
  }

  constructor() {
    this.setupEvents()
  }

  setupEvents() {
    let self = this

    $(document).on("change", "[data-product-version-selector='true']", function(e) {
      self.visitSelectedUrl($(e.target))
    })
  }

  visitSelectedUrl($select) {
    let self = this
    let url = $select.find("option:selected").data("url")
    if(typeof url !== "undefined") {
      self.visitUrl(url)
    }
  }

  visitUrl(url) {
    if(typeof Turbolinks != "undefined") {
      Turbolinks.visit(url)
    } else {
      document.location.href = url
    }
  }
}

export default ProductVersionSelector
